<template>
<div :class="' fixed inset-0 flex z-50 items-center justify-center ' + (show ? 'fixed' : 'hidden')" style="background:#0003; z-index:100;">
  <div class="popup-contain w-full max-w-6xl relative h-full sm:h-auto">
    <div class="">
      <div v-on:click="() => onClose()" class="z-50 point close lg:absolute top-0 lg:border lg:-mt-4 lg:-mr-4 lg:rounded-full w-full lg:w-12 h-12 text-2xl bg-gray bg-gray-100 lg:bg-white text-black mt-2 mr-2 font-medium cursor-pointer border-black text-center right-0 mx-auto flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.707 12.707">
          <g id="Group_1" data-name="Group 1" transform="translate(-94.146 -275.146)">
            <line id="Line_1" data-name="Line 1" x2="12" y2="12" transform="translate(94.5 275.5)" fill="none" stroke="#464646" stroke-width="1" />
            <line id="Line_2" data-name="Line 2" y1="12" x2="12" transform="translate(94.5 275.5)" fill="none" stroke="#464646" stroke-width="1" />
          </g>
        </svg>
      </div>
    </div>
    <div v-on:click.stop v-body-scroll-lock="show" class="max-w-6xl mx-auto shadow-md popup-inner overflow-y-scroll overflow-x-hidden relative" v-if="show">
      <slot></slot>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['show'],
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
}
</script>
